.payment_type_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment_card {
    box-shadow: 0px 0px 2.33px 0px #00000040;
    border: 2.33px solid transparent;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 30px;
}

.payment_card svg path {
    fill: #004c7a !important;
}

.payment_card span {
    color: #4A4A4A;
    font-weight: 600;
    font-size: 18px;

}

.payment_card:hover {
    border: 2.33px solid #F27A21
}

.payment_card:hover svg path {
    fill: #F27A21 !important;

}

.payment_type_inner {
    padding: 40px 60px;
    box-shadow: 0px 0px 5.18px 0px #00000040;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #FFF;


}

.payment_type_inner p {
    color: #4A4A4A;
    font-size: 30px;
    font-weight: 600;
    width: 100%;
    max-width: 470px;
    line-height: 43.45px;
    text-align: center;


}

.scanner_inner {
    padding: 40px 60px;
    box-shadow: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #FFF;
}

.scanner_inner p {
    width: 100%;
    max-width: 300px;
}

.card_inner p {
    width: 100%;
    max-width: 100%;
}

.back_btn {
    background: white;
    border: 1px solid #1B4B7C;
    color: #1B4B7C;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 10px;
    width: 100%;
}

.back_btn:hover {
    background: #1B4B7C;
    border: none;
    color: white;

}

.scanner_container img {
    width: 100%;
    max-width: 460px;
}