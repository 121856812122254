p.tipping_text {
    color: #363954;
    /* font-family: Quicksand; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.tippinglist ul.tippingsection label {
    color: #363954;
    /* font-family: Poppins; */
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    margin-left: 10px;
}



/* .tippinglist {
    margin-top: 50px;
} */



ul.tippingsection li {
    /* margin-right: 14px; */
    width: 100%;
    max-width: 190px;
    margin-bottom: 48px;
}

ul.tippingsection .form-check-tipping input {
    width: 30px;
    height: 30px;
    appearance: none;
    border-radius: 10%;
    border: 1px solid #C2C5F0;
    box-shadow: none;
}

ul.tippingsection .form-check-tipping {
    display: flex;
    align-items: center;
}


ul.tippingsection {
    display: flex;
    flex-flow: wrap;
    justify-content: normal;
}




ul.tippingsection li .form-check-tipping input[type='checkbox']:checked:after {
    content: '\2713';
    color: white;
    font-size: 36px;
    text-align: center;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}


ul.tippingsection li .form-check-tipping input[type='checkbox']:checked:after {
    background-color: #68CBE5;
}

.tippinglist .css-13cymwt-control,
.tippinglist .css-t3ipsp-control {
    border-radius: 50px !important;
    height: 52px !important;
    border-color: #C2C5F0;
}

.tippingcard div#dataTable_wrapper {
    border-bottom: 1px solid #ccc;
    padding: 30px 40px;
}


.tippingcard .tippinglist {
    /* margin-top: 50px; */
    border-bottom: 1px solid #ccc;
    padding: 20px 40px;
}

.menucheckbox {
    border-radius: 50px !important;
}