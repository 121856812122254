
   .customization_image_upload_btn{
   position: relative;

   }
   .customization_image_upload_btn input[type="file"]{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 44px;
    padding: 6px 0px;
    opacity: 0;

   }
   .maximum_number_field{
    width: 100%;
    max-width: 50px;
    padding:6px 3px !important; 
   }