.Myorder .Yourdetails {
    color: #FFF;
    /* text-align: center; */
    font-size: 18.636px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* width: 182.219px; */
    /* height: 55.357px; */
    /* flex-shrink: 0; */
    background: #3E85AC;
    padding: 10px 10px;
    box-shadow: 1px 1px 39px 0px rgba(0, 0, 0, 0.10);
    border-top-left-radius: 10.353px;

    border-bottom-right-radius: 10px;
}

.Myorder .Yourdetails img {
    margin-right: 10px;
}


.Myorder .Detailsuser label {
    color: #454545;
    font-size: 16.565px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



.Myorder .Detailsuser input {
    border-radius: 10.353px;
    border: 1.035px solid #E4E3E3;
    background: #FBFBFB;
    color: #1B4B7C;
    font-size: 18.636px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 18px;
    width: 100%;
}

.Myorder .Detailsuser {
    border-radius: 10.353px;
    background: #FFF;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    padding: 30px 20px;
    margin-bottom: 26px;
}


.Myorder .Yourdetails {
    position: absolute;
    top: 0;
    left: 0;
}




.Myorder .GiftCard {
    border-radius: 10.353px;
    background: #FFF;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 19px;
    margin-bottom: 20px;
}


.Myorder .Card_list .card_img {
    color: #1B4B7C;
    /* font-family: Inter; */
    font-size: 20.707px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    gap: 10px;
}

.Myorder .Card_list button.btn.btncard {
    color: #FFF;
    text-align: center;
    font-size: 18.707px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 10.353px;
    background: #F27A21;
    outline: none;
}


.Myorder .Card_list {
    display: flex;
    justify-content: space-between;

}


.Myorder .GiftCard p {
    color: #1B4B7C;
    font-size: 16.565px;
    font-style: normal;
    font-weight: 400;
    line-height: 130.3%;
    width: 541px;
    padding: 17px 0;

}



.Myorder .GiftCard .AddTip h5 {
    color: #1B4B7C;
    /* font-family: Inter; */
    font-size: 20.707px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}







.card_img p {
    color: #1B4B7C;
    font-family: Inter;
    font-size: 16.565px;
    font-style: normal;
    font-weight: 400;
    line-height: 130.3%;
}

.Myorder .GiftCard .AddTip h5 {
    color: #1B4B7C;
    /* font-family: Inter; */
    font-size: 20.707px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}



.Myorder .GiftCard .AddTip ul li span {
    color: #FFF;
    text-align: center;

    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

.Myorder .GiftCard .AddTip ul li {
    border-radius: 10.353px;
    background: #1B4B7C;
    width: 78px;
    display: flex;
    flex-flow: column;
    gap: 2px;
    padding: 7px 7px;
    margin: 0 4px;
}

.Myorder .GiftCard .AddTip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 0px;
}

.Myorder .GiftCard .AddTip ul {
    display: flex;
    justify-content: space-between;
}

.Myorder .GiftCard .AddTip ul li:nth-child(1) {
    background-color: #000;
}


.Myorder .GiftCard .Card_list .card_input p {
    color: #F27A21;
    font-size: 18.421px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    height: 59px;
    width: 59px;
    border-radius: 45px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}


.Myorder .GiftCard .Card_list button.Minus_btn {
    color: GRAY;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 80.46px;
    background: #FFF;
    box-shadow: 0px 0px 7.515px 0px rgba(0, 0, 0, 0.20);
    height: 40px;
    width: 40px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 0 10px;
}

.Myorder .GiftCard .Card_list button.Minus_btn:hover {
    background: #F27A21;
    color: #FFF;
}


.Myorder .GiftCard .Card_list .card_input span {
    border-radius: 80.46px;
    background: #FFF;
    box-shadow: 0px 0px 7.515px 0px rgba(0, 0, 0, 0.20);
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F27A21;
    font-size: 15px;
    font-style: normal;
    text-align: center;
}

.Myorder .GiftCard .Card_list .card_input {
    display: flex;
    align-items: center;
}

.Myorder .GiftCard .AddTip ul li.active {
    background: #F27A21;
}

.Myorder div#accordion .card {
    border-radius: 10.353px !important;
    background: #FFF;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 19px;
    margin-bottom: 20px;
}



.Myorder div#accordion .card-header {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 6px;
    padding-right: 6px;
    /* border-radius: 56px !important; */
    background: transparent;
    border: none;
    /* padding: 0; */
}

.Myorder div#accordion .card button.btn.btn-link {
    color: #174B7C;
    font-size: 20px;
}


.Myorder div#accordion div#collapseOne {
    border-top: 1px solid #D9D9D9;
    margin-top: 18px;
}



.Myorder div#accordion div#collapseOne .Price_div h5 {
    color: #1B4B7C;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Myorder div#accordion div#collapseOne .Price_div p {
    color: #F27A21;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

.Myorder div#accordion div#collapseOne .Price_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #D9D9D9;
    padding-top: 15px;
    margin-bottom: 15px;
}



.Myorder div#accordion div#collapseOne .card-body ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}



.Myorder div#accordion div#collapseOne .card-body ul li h6,
.Myorder div#accordion div#collapseOne .card-body ul li h5 {
    color: #454545a6;
    /* font-family: Inter; */
    font-size: 14.707px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}


.Save_div {
    border-radius: 10.353px;
    background: #EEF7FF;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    padding: 20px 18px;
    color: #454545;
    font-size: 18px;
}


.cart_pergrap {
    color: #454545;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 11px;
    border: 1px solid gray;
    padding: 3px 12px;
    border-radius: 8px;
    margin-right: 8px;
}


button.Apply {
    border-radius: 10.353px;
    border: 1.035px solid #E4E3E3;
    background: #ff7434;
    color: #fff;
    font-size: 18.636px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 10px 18px;
    position: absolute;
    top: 0;
    right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.appcupan {
    width: 100%;
    position: relative;
}


ul.Discoun_p h5 {
    color: #1B4B7C;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-top: 20px;
    padding-bottom: 20px;
}

.switch-off,
.switch-on {
    top: 6px;
}