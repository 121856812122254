.pageName{
    border-bottom: 1px solid rgb(189, 189, 189);
    padding-bottom: 10px;
}
.page-item.active .page-link {
 
    background-color: #174B7C;
    border-color: #174B7C;
}
.delete_btn{
    border-radius: 50%;
    padding: 5px 9px;
    border: none;
    outline: none;

}