.allsection {
  background: #E6ECEF;
  width: 100%;
}



body .shadow-sm {
  box-shadow: 0 .125rem .25rem 0 rgba(58, 59, 69, .2) !important;
}



body .card-custom_box {
  border-radius: 20px;
  background: #F9F9FA;
}



body .card-custom_box .h5 {
  color: #174B7C;
}



.count-text {
  color: #174B7C;
  font-weight: 600;
  margin-top: 12px;
  opacity: 0.7;
  column-gap: 15px;
  display: flex;
  align-items: center;
}


/*-----------------------------------------*/

/* For large devices like desktops */
@media (min-width: 992px) {
  .side_div_menu {
    display: flex;
    height: 100%;
  }

  /* .allsection {
      padding: 20px; 
    } */
  .card-custom_box {
    padding: 20px;
    /* Adjust padding as needed */
  }
}

/* For medium devices like tablets */
@media (max-width: 991px) {
  .side_div_menu {
    display: block;
  }

  .allsection {
    padding: 15px;
    /* Adjust padding as needed */
  }

  .card-custom_box {
    padding: 15px;
    /* Adjust padding as needed */
  }
}

/* For small devices like mobile phones */
@media (max-width: 767px) {
  .side_div_menu {
    display: block;
  }

  .allsection {
    padding: 10px;
    /* Adjust padding as needed */
  }

  .card-custom_box {
    padding: 10px;
    /* Adjust padding as needed */
  }
}