.mycart .Cart_item {
    border-radius: 10.353px;
    background: #FFF;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    padding: 30px 20px;
    margin-bottom: 20px;
}

.mycart .cartdetails {
    color: #FFF;
    /* text-align: center; */
    font-size: 16.636px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    /* width: 182.219px; */
    /* height: 55.357px; */
    /* flex-shrink: 0; */
    background: #3E85AC;
    padding: 10px 26px;
    box-shadow: 1px 1px 39px 0px rgba(0, 0, 0, 0.10);
    border-top-left-radius: 10.353px;
    border-bottom-right-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
}




.mycart .Card_img {
    display: flex;
    justify-content: space-between;
    /* padding-top: 50px; */
    padding-bottom: 20px;
}


.mycart .Card_img .imgcart img {
    width: 100%;
    max-width: 192px;
    height: 100%;
    object-fit: contain;
    border-radius: 14px;
    max-height: 190px;
    /* min-height: 190px; */

}


.cart_text h5 {
    color: #1B4B7C;
    /* font-family: Inter; */
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.cart_text {
    width: 100%;
    max-width: 450px;
}

.price-text span {
    color: #F27A21;
    font-weight: 700;
    font-size: 16px;
}

.price-text {
    color: #1B4B7C;
    /* font-family: Inter; */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}




.all_cart_btn button {
    color: #000;
    text-align: center;
    /* font-family: Inter; */
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    border-radius: 10.345px;
    border: 1.852px solid #1B4B7C;
    background: rgba(255, 255, 255, 0.22);
    padding: 8px 34px;
    margin-right: 16px;
    margin-top: 16px;
}


.all_cart_btn button svg {
    font-size: 18px;
    width: 22px;
    margin-right: 10px;
}

.all_cart_btn button:hover,
.all_cart_btn button:hover svg path {
    background: linear-gradient(0deg, #F27A21 0%, #F27A21 100%);
    color: #ffff;
    fill: #fff;
    border: 1.852px solid #F27A21;
}

.specialinstruction h6 {
    color: #454545;
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.specialinstruction {
    border-radius: 10.353px;
    background: #FFF;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    padding: 30px 20px;
}


.specialinstruction textarea#highlights {
    border-radius: 10.345px;
    border: 1.034px solid #E4E3E3;
    background: #EEF7FF;
    width: 100%;
    color: #454545;
    /* font-family: Inter; */
    font-size: 16.552px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 12px 10px;
    margin-top: 14px;
    outline: none;
}


.menu_quantity {
    display: flex;
    justify-content: space-between;
    flex-flow: row-reverse;

}


.ContinueOrder {
    text-align: right;
    padding: 17px 0;
    border-radius: 10.353px;
    background: #FFF;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    padding: 15px 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.all_list,
.all_liscus {
    display: flex;
    flex-flow: wrap;
}


.bundlist,
.allcus {
    display: flex;
    flex-flow: wrap;
}

.allbundata {
    display: flex;
    flex-flow: wrap;
}



.ContinueOrder .btn {
    background: white;
    border: 1px solid #F27A21;
    color: #F27A21;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 10px;
    /* color: #FFF; */
    text-align: center;
    /* font-family: Inter; */
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    /* float: right;
    margin-top: 59px; */
}

.ContinueOrder .btn:hover {
    background-color: #F27A21;
    color: #ffff;
}

.Totalamout {
    color: #1B4B7C;
    /* font-family: Inter; */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Totalamout span {
    color: #F27A21;
    font-weight: 700;
    font-size: 16px;
}


.cart_text p {
    color: #454545;
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-top: 14px;
}