.designation_form_container textarea{
    border: 1px solid #404BDA4D;
    padding: 10px 20px;
    border-radius: 10px;
    outline: none !important;
    background-color: #FFFFFF !important;
    
}
.item_uploaded_img{
    width: 100%;
    max-width: 70px;
    min-width: 70px;
    height: 100%;
    max-height: 60px;
    min-height: 60px;
    object-fit: contain;
}
.item_image_container{
    display: flex;
    align-items: center;
    gap: 50px;

}
.item_image_upload_btn{
    position: relative;


}
.item_image_upload_btn button {
    border: none;
    background: #5FC4BE;
    color: white;
    padding: 15px 40px ;
    border-radius: 30px;
    font-weight: 500;

}
.item_image_upload_btn input[type='file']{ 
   position: absolute;
   top: 0px;
   left: 0px;
   padding: 0px;
  width: 190px;
  height: 60px;
  opacity: 0;
}
.add_more_field_btn{
    border: none;
    background: #5FC4BE;
    color: white;
    padding: 15px 25px ;
    border-radius: 30px;
    font-weight: 500;
    cursor: pointer;
}
.items_save_btn_container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px 0px;
    margin-top: 100px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 0px 0px inset;
}