@media screen and (min-width:768px) and (max-width:1200px) {
    .restroSearch{
        position: relative;
        right: 100px !important;
    }
    
}

/* .cnclbutton{
    box-shadow: none;
    background-color: aqua;
} */

/* table#dataTable thead tr th {
    padding-right: 0px;
} */