.topbar {
    height: 4.375rem;
    box-shadow: 0px 2px 2px 0px #272A4517;
}


.Dashbored .topbar .page-title h4 {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    color: #174B7C;
    margin: 0;
    column-gap: 14px;
}


.navbar {
    padding: .5rem 1rem;
    flex-flow: row nowrap;
    justify-content: space-between;
}


img.img-profile.imgpro {
    background: #004c7a;
    padding: 7px 9px;
    border-radius: 11px;
}


img.img-profile {

    border-radius: 11px;
    width: 123px;
    height: 64px;
    object-fit: contain;
}

a.logout-btn_menu span {
    font-size: 13px;
    font-weight: 500;
    align-items: center;
    color: #174B7C;
    margin-top: 10px;
}