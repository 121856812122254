.item_add_left_side_cont {
    background: linear-gradient(3.76deg, #1B4B7C 56.55%, #3E85AC 106.67%, #68CBE5 166.43%);
    border-radius: 20px;
    padding: 28px 32px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    /* padding-bottom: 47px; */
    max-height: 421px;
    min-height: 410px;

}

.item_add_left_side_cont p {
    color: white !important;

}



.image_cont p.coutcat {
    font-size: 20px;
    color: #fff;
    font-weight: 500;
    float: right;
}



.image_cont img {
    width: 100%;
    max-width: 250px;
    height: 191px;
    object-fit: contain;
}


body .image_cont h5 {
    font-size: 24px;
    color: #ffff;
    font-weight: 600 !important;
    line-height: 38px !important;
    margin-bottom: 14px !important;
    margin-top: 14px !important;
}


p.Discu {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    float: left;
    line-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}




.Tab_menu ul#myTab .nav-link {
    color: #454545;
    font-size: 18px;
    font-weight: 500;
    padding-top: 35px;
    padding-bottom: 16px;
    padding-left: 0px;
    padding-right: 0px;
    background: #ffff;
    border: none;
    border-radius: 0;
    border-bottom: 6px solid #D9D9D9;
    width: 100%;
    white-space: pre;
    text-align: center;
}

ul#myTab {
    /* justify-content: space-between; */
    background-color: #fff;
    flex-flow: row;
}


ul#myTab li.nav-item {
    width: 100%;
}

.Tab_menu ul#myTab .nav-link.active {
    color: #F27A21;
    border-bottom: 6px solid #F27A21;
}








.Tab_menu div#myTabContent {
    background: #fff;
    padding: 40px 27px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}




.Tab_menu div#myTabContent.tab-pane p {
    color: #454545;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
}




div#accordion .card button.btn.btn-link {
    color: #174B7C;
    font-size: 30px;
    font-weight: 700;
    line-height: 42px;
    outline: none;
    text-decoration: none;
    border: none;
    padding: 0;
    box-shadow: none;

}




div#accordion .card {
    border: 1px solid #F1F1F1;
    border-radius: 33px !important;
    margin-bottom: 10px;
}




div#accordion .card-header {
    padding-top: 40px;
    padding-bottom: 30px;
    padding-left: 28px;
    padding-right: 28px;
    /* border-radius: 56px !important; */
    background: transparent;
    border: none;
    /* padding: 0; */
}



div#accordion .card-header h5 span {
    color: #F27A21;
    font-weight: 800;
    font-size: 22px;
}



ul.indredent_item li {
    color: #454545;
    font-size: 18px;
    font-weight: 400;
    line-height: 42px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    margin: 0 0px;
    background: transparent;
    padding: 0px 0px;
    border-radius: 9px;
    margin-bottom: 6px;
}


ul.indredent_item li span.value_text {
    color: #454545;
    font-weight: 400;
}

ul.indredent_item {
    display: flex;
    flex-flow: wrap;
}


.customizationdata h5,
.customizationbundle h5 {
    color: #174B7C;
    font-size: 25px;
    font-weight: 500 !important;
    line-height: 21px !important;
    margin-bottom: 12px !important;
}








.customizationrediobtn .form-check {
    padding-left: 0;
    /* display: flex; */
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 5.857px 0px rgba(0, 0, 0, 0.25);
    padding: 25px 35px;
    height: 90px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    width: 269px;
    margin: 10px 10px;
    border-radius: 10px;
    margin-left: 0;
}




.customizationrediobtn .menu_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.customizationrediobtn label.form-check-label {
    color: #1B4B7C;
    font-size: 20px;
    font-weight: 600;
    line-height: 21px;
}



.customizationrediobtn .form-check-input {
    height: 25px;
    width: 25px;
    position: unset;
}

.customizationrediobtn {
    display: flex;
    flex-flow: wrap;
}


.customizationrediobtn .form-check.selected,
.customizationrediobtn .form-check.Cardsection.selected,
.bundlemodal .customizationrediobtn .form-check.Cardsection.selected,
.bundlemodal .customizationrediobtn .form-check.selected,
body .bundle_name .bundle_card.selected {
    background-color: #F27A21;
}


.customizationrediobtn .form-check.selected label.form-check-label,
.customizationrediobtn .form-check.Cardsection.selected label.form-check-label,
.bundlemodal .customizationrediobtn .form-check.Cardsection.selected label.form-check-label,
.bundlemodal .customizationrediobtn .form-check.selected label.form-check-label,
.bundlemodal .customizationdata .form-check.selected h5,
body .bundle_name .bundle_card.selected h5,
body .bundle_name .bundle_card.selected p {
    color: #ffff;
}



.customizationrediobtn .form-check h5 {
    color: #F27A21;
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-top: 5px;
}


.customizationrediobtn .form-check.selected h5,
.customizationrediobtn .form-check.Cardsection.selected h5 {
    color: #fff;
}





.customizationrediobtn .form-check.Cardsection {
    padding-left: 0;
    /* display: flex; */
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 5.857px 0px rgba(0, 0, 0, 0.25);
    padding: 26px 13px;
    height: 183px;
    width: 178px;
    position: relative;
    border-radius: 10px;
    margin-left: 0;
}



.customizationrediobtn .form-check.Cardsection img {
    width: 150px;
    height: 72px;
    margin-bottom: 14px;
    min-height: 72px;
}



.customizationrediobtn .form-check.Cardsection .form-check-input {
    height: 25px;
    width: 25px;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 21px;
    /* z-index: 0 !important; */
    opacity: 0;
}


.customizationdata {
    margin-bottom: 30px;
}






/* div#accordion .collapse .card-body {
    height: 100%;
    max-height: calc(100vh - 400px);
    overflow-y: scroll;
    overflow-x: hidden;
} */





div#accordion .collapse .card-body::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #D9D9D9;
}

div#accordion .collapse .card-body::-webkit-scrollbar {
    width: 5px;
    background-color: #D9D9D9;
}

div#accordion .collapse .card-body::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #363954;
}











.bundleCategories .customizationbundle .bundle_card h5 {
    color: #1B4B7C;
    font-size: 20px;
    font-weight: 600;
    line-height: 6px;
    margin-bottom: inherit;
}

.bundleCategories .customizationbundle .bundle_card p {
    color: #F27A21;
    /* font-family: Inter; */
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding-top: 0px;
}

.bundleCategories .customizationbundle .bundle_card {
    background: #FFF;
    box-shadow: 0px 0px 5.857px 0px rgba(0, 0, 0, 0.25);
    padding: 0px 0px;
    height: 228px;
    width: 178px;
    position: relative;
    border-radius: 10px;
    margin-left: 0;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 10px;
}

.bundleCategories .customizationbundle .bundle_card img {
    width: 100%;
    width: 178px;
    height: 115px;
    object-fit: fill;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


.bundleCategories .customizationbundle .bundle_name {
    display: flex;
    flex-flow: wrap;
    justify-content: start;
    margin-bottom: 40px;
}



.bundleCategories .customizationbundle h5 {
    margin-bottom: 0 !important;
    padding-top: 10px;
}



/* popup css */

.bundlemodal .item_add_left_side_cont {
    padding: 10px 10px;
    max-height: 230px;
    min-height: 230px;

}


.bundlemodal .image_cont p.coutcat {
    font-size: 13px;
}



.bundlemodal .image_cont img {
    /* width: 100%; */
    max-width: 100%;
    height: 120px;
    object-fit: fill;
}



body .bundlemodal .image_cont h5 {
    font-size: 16px;
    line-height: 15px !important;
    margin-bottom: 8px !important;
    margin-top: 8px !important;
}


body .bundlemodal p.Discu {
    font-size: 12px;
    line-height: 16px;
}

body .bundlemodal .Tab_menu ul#mybundel .nav-link {
    color: #454545;
    font-size: 10px;
    font-weight: 500;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 0px;
    padding-right: 0px;
    background: #ffff;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #D9D9D9;
    width: 100%;
    text-align: center;
    white-space: pre;
}


body .bundlemodal .Tab_menu ul#mybundel .nav-link.active {
    border-bottom: 2px solid #F27A21;
}


body .bundlemodal .Tab_menu div#myTabbundel {
    padding: 10px 10px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}


body .bundlemodal .Tab_menu div#myTabbundel ul.indredent_item li {
    color: #000;
    font-size: 11px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    margin: 0 0px;
    background: transparent;
    padding: 0px 0px;
    border-radius: 9px;
    margin-bottom: 5px;
}




div#accordion1 .card button.btn.btn-link {
    color: #174B7C;
    font-size: 20px;
    font-weight: 700;
    line-height: 15px;
    outline: none;
    text-decoration: none;
    border: none;
    padding: 0;
    box-shadow: none;

}




div#accordion1 .card {
    border: 1px solid #F1F1F1;
    border-radius: 33px !important;
    margin-bottom: 10px;
}




div#accordion1 .card-header {
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    /* border-radius: 56px !important; */
    background: transparent;
    border: none;
    /* padding: 0; */
}



div#accordion1 .card-header h5 span {
    color: #F27A21;
    font-weight: 800;
    font-size: 14px;
}

.bundlemodal .customizationdata h5 {
    color: #174B7C;
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 21px !important;
    margin-bottom: 12px !important;
}


.bundlemodal .customizationrediobtn {
    display: flex;
    flex-flow: wrap;
}

.bundlemodal .customizationrediobtn .form-check {
    padding-left: 0;
    /* display: flex; */
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 5.857px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 15px;
    height: 64px;
    width: 178px;
    margin: 10px 10px;
    border-radius: 10px;
    margin-left: 0;
}




.bundlemodal .customizationrediobtn label.form-check-label {
    color: #1B4B7C;
    font-size: 12px;
    font-weight: 600;
    line-height: 6px;
}





.bundlemodal .customizationdata .form-check h5 {
    color: #F27A21;
    font-size: 12px;
    font-weight: 500 !important;
    line-height: 4px !important;
    margin-bottom: 0px !important;
}



.bundlemodal .customizationdata .form-check-input {
    height: 15px;
    width: 15px;
    position: unset;
}


.bundlemodal .customizationrediobtn .form-check.Cardsection {
    padding-left: 0;
    /* display: flex; */
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 5.857px 0px rgba(0, 0, 0, 0.25);
    padding: 15px 15px;
    height: 182px;
    width: 150px;
    margin: 10px 10px;
    border-radius: 10px;
    margin-left: 0;
}


.bundlemodal .customizationrediobtn .form-check.Cardsection img {
    width: 120px;
    height: 56px;
    margin-bottom: 6px;
    object-fit: fill;
    margin: 3px auto;

}



.bundlemodal .customizationrediobtn .form-check.Cardsection .form-check-input {

    top: -5px;
    left: 20px;
    /* z-index: 0 !important; */
    /* opacity: 0; */
}

.bundlemodal .customizationrediobtn .Cardsection .menu_input {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}


.bundlemodal .customizationdata .form-check.Cardsection h5 {
    text-align: center;
}


.bundlemodal .modal_btn_container {
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;
    margin-bottom: 20px;
}

.basket_button {
    background: white;
    border: 1px solid #F27A21;
    color: #F27A21;
    font-size: 18px;
    font-weight: 700;
    padding: 10px 20px;
    border-radius: 10px;
}

.basket_button:hover {
    background: #F27A21;
    border: none;
    color: white;

}

.customizationrediobtn .form-check {
    padding: 25px 35px;
    height: 100px;
}

ul#mybundel {
    flex-flow: row;
}