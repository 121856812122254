p {
  font-size: 14px;
  font-weight: 500;
  color: #363954;
  line-height: 20px;
}


.eys_input {
  position: relative;
}

span.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translate(-50%, -50%);
}

.colormenu .colordiv {
  box-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38);
}

.css-1tqrhto>.ps-menu-button:hover {
  background-color: transparent;
}



/* new css  */




.addrolemange .css-13cymwt-control,
.addrolemange .css-t3ipsp-control,
.addrolemange .css-t3ipsp-control:hover,
.addrolemange .css-t3ipsp-control:active,
.addrolemange .dropdown-container,
.addrolemange .dropdown-container:hover,
.addrolemange .dropdown-container:active {
  border-radius: 50px !important;
  box-shadow: 0px 0px 2px 0px #00000040;
  padding-left: 15px;
  border-color: transparent !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-color: transparent !important;
  /* min-height: 48px !important; */
}

.addrolemange .dropdown-container:disabled {
  border-radius: 50px !important;
}

input#react-select-15-input {
  box-shadow: none !important;
}


.addrolemange div.dataTables_filter {
  text-align: left !important;
}

.addrolemange input.form-control {
  border-radius: 50px !important;
  box-shadow: 0px 0px 2px 0px #00000040;
  padding-left: 15px;
  border-color: transparent !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border-color: transparent !important;
  min-height: 50px !important;
}


.modal_btn_container.role_btn {
  justify-content: center;
}

.modal_btn_container.role_btn button,
.modal_btn_container.role_btn .modal_cancel_btn {
  max-width: 215px;
  min-width: 215px;
}

.css-1tqrhto>.ps-menu-button:hover {
  background-color: transparent !important;
}


.Prefectuser label {
  color: #363954;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 0;
}


/* .Prefectuser {
  padding-top: 30px;
  padding-bottom: 30px;
} */


.Line_div {
  border-bottom: 1px solid #D7D8E0;
  /* padding-top: 20px; */
}


.space_div {
  padding: 20px;
}

.hover_active:hover {
  text-decoration: underline;
  cursor: pointer;
}

a.Back_brn {
  background: #004c7a;
  padding: 5px 10px;
  margin-bottom: 30px;
  color: #ffffff;
  border-radius: 8px;
}


a.Back_brn:hover {
  background: #fff;
  color: #004c7a;
}








.MuiBox-root.css-dwm1pv,
.MuiBox-root,
.css-dwm1pv {
  overflow-y: scroll;
  overflow-x: hidden;
}




.css-dwm1pv::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  background-color: #D9D9D9;
}

.css-dwm1pv::-webkit-scrollbar {
  width: 8px;
  background-color: #D9D9D9;
}

.css-dwm1pv::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3); */
  background-color: #363954;
}


body .css-1tqrhto>.ps-menu-button {
  padding-left: 43px;
}



select#selectstatis {
  border: 1px solid rgba(64, 75, 218, 0.3);
  /* border-radius: 24px; */
  min-height: 25px;
  font-weight: 500;
  text-indent: 1px;
  box-shadow: none !important;
  width: 100%;
  font-size: 14px;
  max-width: 102px;
  min-width: 102px;
  appearance: none;
  background: white url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="Charade" d="M0 0l5 5 5-5z"/></svg>') no-repeat right 10px center;
  background-size: 10px;
}

button.resetbutton {
  background: #174B7C;
  border: none;
  color: #fff;
  padding: 4px 12px 4px 12px;
  border-radius: 4px;
  margin-left: 20px;
}

.css-16xfy0z-control {
  height: 52px !important;
  border-radius: 50px !important;
}

.css-18unl23 {
  background-color: transparent !important;
}





.login-inner .small:hover {
  text-decoration: underline !important;
  color: #ffa338;
}

body .orderboat {
  background-color: #fff;

}