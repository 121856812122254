.iconContainer {
  font-size: 26px;
  display: flex;
  position: relative;
  left: 145%;
  bottom: 50%;
  column-gap: 15px;
  width: 75px;
  height: 40px;
}

.listiconContainer {
  font-size: 26px;
  display: flex;
  position: relative;
  left: 140%;
  bottom: 40%;
  column-gap: 15px;
  width: 75px;
  height: 35px;
}

.card-Container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #e9ecef;
}

.card-head p {
  font-size: 10px;
  font-weight: bold;
  margin: 0 5px;
  display: flex;
  align-items: center;
}

/* 
.card-body {
  padding: 10px;
} */

.order-item-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.order-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  height: 80%;
}

.order-items::-webkit-scrollbar {
  width: 5px;
}

.order-items::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.order-items::-webkit-scrollbar-thumb {
  background: #14caa9;
  border-radius: 5px;
}

.order-items::-webkit-scrollbar-thumb:hover {
  background: #1ab697;
}

.order-item {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px;
  border-bottom: 1px solid #eee;
}

.order-item p {
  margin: 0;
  font-size: 14px;
}

.status {
  background-color: #36d7b7;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: bold;
}

.searchCon {
  position: relative;
  left: 20px;
}

.selectedOrder {
  border: none;
  border-radius: 2px solid grey;
  background-color: transparent;
  box-shadow: none;
}

/* .image1{
border: 1px solid black;
border-radius: 4px ;
padding: 5px;
}
.image1:hover{
  background-color: #F27A21;
}
.image2:hover{
  background-color: #F27A21;
}
.image2{
border: 1px solid black;
border-radius: 4px ;
padding: 5px;
} */


/* .image1,
.image2 {
  border: 1px solid black;
  border-radius: 4px;
  padding: 5px;
  transition: 0.1s ease-in-out;
  background-color: #f5b483;

}

.image1:hover,
.image2:hover {

  background-color: #F27A21;
}

.selected {
  background-color: #F27A21;
} */



/* @media (max-width:426px) {
  .listiconContainer {
    font-size: 26px;
    display: flex;
    position: relative;
    left: 190% !important;
    bottom: 130%;
    column-gap: 15px;
    width: 75px;
    height: 35px;
  }
} */

/* FOR MOBILE SCREEN */

/* @media (max-width:767px){
  .card-Container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 20px;
  }

  /* .show {
    position: relative;
    right: 90px;
    font-size: 40px;
    height: 140px !important;
  } */

/* .searchCon {
    position: relative;
    bottom: 15%;
    left: 25%;
    width: 50% !important;

  }

  .inputSearch {
    width: 70% !important;
    font-size: 13px;
  }

  .statusCon {
    position: relative;
    right: 62px;
    font-size: 5px;
    width: 15px;
  }

  .statusCon1 {
    position: relative;

    bottom: 45px;
    right: 70px;
  }

  .showDiv {
    position: relative;
    left: 90px;
    top: 20px;
  }

  .footcon {
    position: relative;
    bottom: 15px;
  }

  .paginateFoot {
    font-size: 9px;
    right: 15px;
  }

  .iconContainer {
    position: relative;
    bottom: 58%;
    left: 105% !important;
  }

  .listiconContainer {
    font-size: 26px;
    display: flex;
    position: relative;
    left: 110%;
    bottom: 130%;
    column-gap: 15px;
    width: 75px;
    height: 35px;
  }

  .list-search {
    position: relative;
    bottom: 110px;
    left: 50px;
  }

  .mainDiv {
    width: 355px;
  } */

/* } */
/* 
@media screen and (min-width:768px) and (max-width:956px){

  .statusCon{
    right: 38%;
    margin-bottom: 100px;
    } 

  .show{
   flex: none;
   margin-right: 20px;
  }

  .searchCon{
    bottom: 40px;
    margin-right: 40px !important;
  }

  .inputSearch{
     width: 80%!important;
   }

  .iconContainer{
   width: 10px;
  }
 
  .icon{
    position: relative;
    right: 40px !important;
  }





  .card-Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    row-gap: 20px;
  }

  .card-head p{
    font-size: 7px !important;
  }

  .listiconContainer {
    font-size: 26px;
    display: flex;
    position: relative;
    left: 140%;
    bottom: 90%;
    column-gap: 15px;
    width: 75px;
    height: 35px;
  }

  .list-search {
    position: relative;
    left: 60px;
  }

  .footcon {
    position: relative;
    
  }

  .footshow {
    width: 60%;
    font-size: 10px;
  }
  .paginateFoot{
    margin-right: 30% !important;
    margin-bottom: 24px;

  }

} */

/* @media screen and (min-width:768px) and (max-width:869px){
  .card-head{
    flex-direction: column;
    gap: 2px;
  }

  
} */

/* @media screen and (min-width:956px) and (max-width:1200px){
  .card-Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    row-gap: 20px;
  }


.clock{
  font-size: 7px !important;
}
  .show{
    position: relative;
    bottom:75px !important;
    font-size: 50px;
  } 
  .searchCon {
    position: relative;
  
    left: 44%;
  }

  .inputSearch {
    width: 58% !important;
    font-size: 13px;
  }

  .statusCon {
    position: relative;
    right: 98px;
    font-size: 5px;
    width: 5px;
  }

  .iconContainer {
    position: relative;
    bottom: 22px;
    left: 140% !important;
  }

  .footcon {
    position: relative;

  }

  .footshow {
    width: 80%;
    font-size: 12px;
  }

  .paginateFoot {
    right: 12% !important;
    margin-bottom: 24px;

  }

  .list-search {
    margin-left: 100px !important;
  }
} */