.order_category_cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 18px 0px;
    border-radius: 10px;
    gap: 10px;
    width: 100%;
    cursor: 'pointer';
    max-width: 150px;

}

.order_category_cont.active {
    background: linear-gradient(4.27deg, #1B4B7C 47.72%, #3E85AC 104.23%, #68CBE5 171.61%);
}

.order_category_cont.active p {
    color: white;
    font-weight: 500;
    font-size: 15px;

}

.order_category_cont p {
    color: #174B7C;
    font-weight: 500;
    font-size: 15px;

}



.category_menu_container {
    height: 100%;
    max-height: (100px, 400px);
    max-height: calc(100vh - 90px);
    overflow-y: scroll;
    overflow-x: hidden;
}





.category_menu_container::-webkit-scrollbar-track {

    border-radius: 5px;
    background-color: #D9D9D9;
}

.category_menu_container::-webkit-scrollbar {
    width: 5px;
    background-color: #D9D9D9;
}

.category_menu_container::-webkit-scrollbar-thumb {
    border-radius: 5px;

    background-color: #F27A21;
}



.custom-switch .custom-control-label::before {
    left: -33px;
    width: 6.4rem;
    pointer-events: all;
    border-radius: 19px;
    height: 39px;
    box-shadow: none !important;
    border: none !important;
}


.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 2rem;
    pointer-events: none;
    content: "";
    background-color: #F27A21;
    border: #F27A21 solid 1px;
}


.custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-2.25rem + 8px);
    width: 35px;
    height: 35px;
    background-color: white;
    /* background-image: url(../../../../../../img/Order/category/delivery.png); */
    border-radius: 18px;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    transform: translateX(3.55rem);
}


.custom-control-label {
    position: relative;
    margin-bottom: 0;
    /* vertical-align: -webkit-baseline-middle; */
    top: -16px;
    left: 33px;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #174B7C;
    background-color: #174B7C;
}




.custom_input_label_right {
    color: white;
    position: absolute;
    left: 42px;
    top: -4px;
    font-size: 15px;
    font-weight: 500;
}

.custom_input_label_left {
    color: white;
    position: absolute;
    left: 9px;
    top: -4px;
    font-size: 15px;
    font-weight: 500;
}

.item_Container {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    max-width: 230px;
    padding: 27px 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    max-height: 325px;
    position: relative;
}

.category_item_img {
    width: 100%;
    /* max-width: 150px; */
    /* min-width: 150px; */
    height: 100%;
    min-height: 150px;
    max-height: 150px;
    object-fit: fill;
    border-radius: 6px;
}

.item_Container .tag_1 {
    color: #F27A21;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    /* padding-left: 24px; */
    position: absolute;
    left: 15px;
    background: #ffff;
    padding: 2px 10px;
    border-radius: 5px;
    top: 33px;
    /* padding-left: 24px; */

}

.item_Container .item_label {
    color: #1B4B7C;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    height: 40px;
    /* padding-left: 24px; */

}

.item_Container .tag_2 {
    color: #5FC4BE;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    /* padding-right: 24px; */

}

.item_original_price {
    color: #FF0000;
    font-weight: 700;
    font-size: 18px;
}

.item_sell_price {
    color: #454545;
    font-weight: 600;
    font-size: 15px;
    text-decoration: line-through;
}

.category_item_container {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
    padding: 30px 5px;
    margin-top: 30px;
}

.category_image_1 {
    width: 100%;
    max-width: 112px;
    min-width: 112px;
    object-fit: initial;
    height: 100%;
    max-height: 100px;
    min-height: 100px;
    border-radius: 6px;
}

.category_page_subheading2 {
    color: #174B7C;
    font-weight: 700;
    font-size: 33px;
}

.order_category .custom-control-input {
    left: 5px !important;
}


section.Restaurants.Dashbored.allsection.menuorder {
    background: #ffff;
}


.custom-switch .custom-control-label.eat-in::after {
    background-color: white;
    background-image: url("../../../../../../img/Order/category/delivery.png") !important;

    /* Replace with your Eat In image URL */
}

.custom-switch .custom-control-label.take-away::after {
    background-color: white;
    background-image: url(" ../../../../../../img/t2.svg");

    /* Replace with your Take Away image URL */
}