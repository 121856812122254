.myrediv h5.hef {
    font-size: 30px;
    font-weight: 600;
    align-items: center;
    color: #174B7C;
}

.usernameres p {
    color: #4A4A4A;
    font-size: 18.623px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0;
}

.usernameres h5 {
    color: #4A4A4A;
    font-size: 19.623px;
    font-style: normal;
    font-weight: 600;
}

.usernameres {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    /* max-width: 183px; */
}

.usernameres h5.succ {
    color: #F27A21;
}


section.Restaurants.Dashbored.allsection.MyReceipt {
    background: #fff;
}



table.table.tabletotal {
    max-width: 29% !important;
    width: 100% !important;
    float: right;
    /* margin-top: 40px; */
}



.boder_teb {
    border-top: 1px solid #dde2e6;
    margin-top: 50px;
}



table.table.tabletotal td.list_teb {
    text-align: center;
}


.modal_btn_container.receipt_btn .modal_submit_btn {
    max-width: 400px;
}

.modal_btn_container.receipt_btn {
    width: 100%;
    justify-content: center;
}

.modal_btn_container.receipt_btn button {
    box-shadow: none !important;
    background-color: #ffff !important;
    border: 1px solid #174B7C !important;
    color: #174B7C !important;
}

.modal_btn_container.receipt_btn button:hover,
.modal_btn_container.receipt_btn .modal_cancel_btn:hover {
    box-shadow: none !important;
    background-color: #F27A21 !important;
    border: 1px solid #F27A21 !important;
    color: #fff !important;
}