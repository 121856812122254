.popup {
  position: fixed;
  top: 0;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow-y:auto;

}

.popup-inner {
  background: white;
  padding: 20px;
  border-radius: 10px;
  left: 10%;
  top: 7%;
  width: 600px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;

}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgb(241, 112, 64);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 25px;
  cursor: pointer;
  width: 35px;
}

h2 {
  margin-bottom: 20px;
  text-align: center;
}

.order-summary {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.order-info p,
.customer-info p {
  margin: 5px 0;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.order-table th,
.order-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.order-table th {
  background-color: #f2f2f2;
}


.totals p {
  margin: 5px 0;
}

.totals p strong {
  display: inline-block;
  width: 100px;

}

@media (max-width:768px) {
  .popup-inner {
    width: 380px;
    top: 12px;

    left: 160px;
  }

}

@media screen and (min-width:768px) and (max-width:956px) {
  .popup-inner {
    width: 440px;
    top: 12px;

    left: 160px;
  }

}

@media screen and (min-width:956px) and (max-width:1115px) {
  .popup-inner {
    width: 520px;
    top: 12px;
    left: 160px;
  }

}