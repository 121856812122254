.sorting_filter_container{
    display: flex;
    align-items: center;
    gap: 10px;
}
.sorting_filter_container select {
    border: 1px solid #D7D8E0;
    color: #727272;
    background-color: white !important;
    padding: 3px 10px ;
    border-radius: 5px;
    outline: none;

    -webkit-appearance: none;  /* Safari and Chrome */
    -moz-appearance: none;     /* Firefox */
    appearance: none;

    background: url('../../../../../img/AddModal/Polygon\ 1.png') no-repeat right center;
    background-position: 95%;
    background-size: 12px;
    padding-right: 20px; /* Adjust this value based on your custom arrow size */
    width: 100%;
    max-width: 120px;
}

.sorting_filter_container .reset_btn {
    border: none;
    background-color: #174B7C;
    color: white;
    padding: 3px 15px;
    border-radius: 5px;
}
.sorting_filter_container .search_btn {
    border: none;
    background-color: #F27A21;
    color: white;
    padding: 3px 15px;
    border-radius: 5px;
}
.category_sequence_field{
    border: 1px solid rgb(202, 202, 202);
    border-radius: 5px;
    padding: 3px;
}
.set_sequence_btn {
    color: white;
    background-color: #5FC4BE;
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
}

@media screen and (max-width:767px){
    .categorySearch{
        margin-left: 30% !important;
    }
}