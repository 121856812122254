.designation_form_container input {
    border: 1px solid #404BDA4D;
    padding: 10px 20px;
    border-radius: 24px;
    outline: none !important;
    background-color: #FFFFFF !important;

}

.designation_form_container select {
    border: 1px solid #404BDA4D;
    padding: 10px 20px;
    border-radius: 24px;
    outline: none !important;
    background-color: #FFFFFF !important;
    -webkit-appearance: none;
    /* Safari and Chrome */
    -moz-appearance: none;
    /* Firefox */
    appearance: none;
    background: url('../../../../../img/AddModal/Polygon\ 1.png') no-repeat right center;
    background-position: 95%;
    background-size: 12px;
    padding-right: 20px;
    /* Adjust this value based on your custom arrow size */


}

body .adddesignation_card {
    height: 100%;
    min-height: 80vh;
}