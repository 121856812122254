@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #858796;
  text-align: left;
  background-color: #fff;
}




h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.2 !important;
  margin: 0 !important;
  font-weight: 600 !important;
}

ol,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure,
p {
  margin: 0;
}

p {
  font-size: 14px;
  font-weight: 500;
  color: #363954;
  line-height: 20px;
}

a {
  -webkit-transition: all .3s ease-in 0s;
  -moz-transition: all .3s ease-in 0s;
  -ms-transition: all .3s ease-in 0s;
  -o-transition: all .3s ease-in 0s;
  transition: all .3s ease-in 0s;
}

iframe {
  border: 0;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  outline: 0;
}




a:focus,
a:hover {
  color: inherit;
}

/*

::placeholder {
    color: #999 !important;
}
*/


.clear:before,
.clear:after {
  content: " ";
  display: table;
}

.clear:after {
  clear: both;
}

/* .clear {
  *zoom: 1;
} */


.bg-green {
  background-color: #5FC4BE !important;
}

.bg-blue {
  background-color: #68CBE5 !important;
}

.bg-orange {
  background-color: #F27A21 !important;
}

.bg-dark-blue {
  background-color: #174B7C !important;
}

.text-navy-blue {
  color: #363954 !important;
}



.bg-gradient-primary {
  background-color: #174B7C;
}




.small,
small {
  font-size: 80%;
  font-weight: 400;
}



.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}


a,
a:focus,
a:hover {
  text-decoration: none !important;
  outline: 0;
}

.login-inner .text-danger {
  text-align: left;
  font-weight: 600;
  padding-top: 10px;
}



body .container,
body .container-fluid,
body .container-sm,
body .container-md,
body .container-lg,
body .container-xl {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}



/* For modern browsers */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}