.add_brach_btn h5 {
    color: #174B7C;
}

.modal_body_container {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.modal_heading h1 {
    color: #363954;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;

}

.field_cont {
    display: flex;
    flex-direction: column;
    gap: 19px;
}

body .Team_modal .modal_form_container input {
    border: 1px solid #404BDA4D;
    padding: 12px 20px;
    border-radius: 24px;
    outline: none !important;
    background-color: #FFFFFF !important;
    /* margin-bottom: 20px; */

}

body .Team_modal .modal_form_container select {
    border: 1px solid #404BDA4D;
    padding: 10px 20px;
    border-radius: 24px;
    outline: none !important;
    background-color: #FFFFFF !important;
    /* margin-bottom: 20px; */
    height: 49px;

    appearance: none;
    background: white url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="Charade" d="M0 0l5 5 5-5z"/></svg>') no-repeat right 10px center;
    background-size: 10px;

}

body .Team_modal .modal_form_container select.selected {
    background-color: #363954;
    /* Change this to the desired color */
    color: red;
    /* Change text color if needed */
}

.modal_btn_container {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 10px;

}

.modal_submit_btn {
    box-shadow: 0px 4px 25px 0px #F27A2133;
    background: #F27A21;
    border: none !important;
    outline: none !important;
    border-radius: 24px;
    padding: 18px 50px;
    color: #F9F9FA;
    font-weight: 600;
    width: 100%;
    max-width: 250px;
    cursor: pointer;
}

.modal_cancel_btn {
    color: #174B7C;
    outline: none !important;
    border-radius: 24px;
    padding: 14px 50px;
    width: 100%;
    max-width: 250px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.modal_cancel_btn:hover {
    background-color: #F27A21;
    color: white;
    border-color: #F27A21;
}

.mobile_number_cont {
    display: flex;
    align-items: center;
    border: 1px solid #404BDA4D;
    border-radius: 24px;
    padding: 10px 20px;

}

.mobile_number_cont input {
    border: none !important;
    outline: none !important;
    padding: 0px 10px;
    overflow: hidden !important;
    border-radius: 0px;
}

.country_code_select {
    width: 100%;
    max-width: 50px;
    padding: 0px !important;
    border: none !important;
    outline: none !important;
    background-color: #fff;

}

.image_container {
    display: flex;
    flex-direction: column;
    gap: 5px
}

.uploaded_image {
    width: 100%;
    max-width: 150px;
    height: 100%;
    max-height: 150px;
    object-fit: contain;
}

.browser_img_btn {
    position: relative;
}

.browser_img_btn>div {
    border: none !important;
    background: #5FC4BE;
    color: #FFFFFF;
    border-radius: 4px;
    width: 100%;
    max-width: 230px;
    padding: 10px;
    text-align: center;

}

.browser_img_btn input[type="file"] {
    position: absolute;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    width: 230px;
    padding: 6px 20px;
    opacity: 0;

}