.bg-red {
    background-color: #E54740 !important;

}

.add_more_category {
    border: 1px solid #174B7C;
    /* box-shadow: 0px 4px 25px 0px #404BDA59; */
    color: #174B7C;
    outline: none !important;
    border-radius: 24px;
    padding: 14px 50px;

    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.add_more_category:hover {
    background-color: #F27A21;
    color: white;
}

ul#mybundel li.nav-item {
    width: 100%;
}