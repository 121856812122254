.meal_list_desc{
    width: 100%;
    max-width: 500px;

}

@media screen and (min-width:768px) and (max-width:1200px){
    .mealSearch{
        position: relative;
        top: 20px;
        right: 50px;
    }
}