@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* @font-face {
    font-family: 'Quantum Flat';
    src: url('../fonts/quantflt.ttf')  format('truetype');
  } */


body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #858796;
    text-align: left;
    background-color: #fff;
}

.text-danger {
    padding-top: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    line-height: 1.2;
    margin: 0;
    font-weight: 600;
}

ol,
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

figure,
p {
    margin: 0;
}

p {
    font-size: 14px;
    font-weight: 500;
    color: #363954;
    line-height: 20px;
}

a {
    -webkit-transition: all .3s ease-in 0s;
    -moz-transition: all .3s ease-in 0s;
    -ms-transition: all .3s ease-in 0s;
    -o-transition: all .3s ease-in 0s;
    transition: all .3s ease-in 0s;
    cursor: pointer;
}

iframe {
    border: 0;
}

a,
a:focus,
a:hover {
    text-decoration: none;
    outline: 0;
}

a,
button {
    cursor: pointer !important;
}


a:focus,
a:hover {
    color: inherit;
}

/*

::placeholder {
    color: #999 !important;
}
*/


.clear:before,
.clear:after {
    content: " ";
    display: table;
}

.clear:after {
    clear: both;
}

/* .clear {
    *zoom: 1;
} */


.bg-green {
    background-color: #5FC4BE !important;
}

.bg-blue {
    background-color: #68CBE5 !important;
}

.bg-orange {
    background-color: #F27A21 !important;
}

.bg-dark-blue {
    background-color: #174B7C !important;
}

.text-navy-blue {
    color: #363954 !important;
}


.login-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
    /* background-image: url(../img/login-bg.png); */
    padding: 50px 0 70px;
    background-size: cover;
    background-position: bottom;
    position: relative;
}

.login-wrapper:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    content: '';
    background: -webkit-linear-gradient(left, #F7E200 0%, #F7E200 16.6%, #5FC4BE 16.6%, #5FC4BE 33.3%, #1B4B7C 33.3%, #1B4B7C 50%, #8C78A2 50%, #8C78A2 66.6%, #68CBE5 66.6%, #68CBE5 83.3%, #F27A21 83.3%, #F27A21 100%);
}

.login-wrapper .card {
    background-color: transparent;
    box-shadow: none !important;
}

.login-inner {
    background: linear-gradient(4.33deg, #1B4B7C 50.4%, #68CBE5 175.84%);
    border-radius: 20px;
}

.login-inner .btn {
    border: none;
    background: linear-gradient(100.82deg, #F27A21 59.46%, #F7E203 108.16%);
    border-radius: 25px;
    padding: 13px;
    font-size: 18px;
    /* font-family: 'Quantum Flat';
    letter-spacing: 1px; */
    font-weight: 600;
}

.login-inner .small {
    color: #fff;
}

.login-inner .form-control {
    border: 2px solid #4078B1;
    border-radius: 24px;
    min-height: 52px;
    font-weight: 500;
    text-indent: 12px;
    box-shadow: none !important;
    background: #1B4B7C;
    color: #fff;
}

.login-inner .form-control::placeholder {
    color: #999ba8;
}

.login-inner .h4.text-gray-900 {
    color: #fff !important;
    /* font-family: 'Quantum Flat';
    letter-spacing: 1px; */
    font-weight: 600;
}



.sidebar-brand-logo img {
    max-width: 142px;
}

.user-name {
    text-align: center;
    color: #fff;
    font-weight: 500;
    margin-bottom: 40px;
}

.user-name p {
    color: #fff;
}


.sidebar-dark .nav-item.active .nav-link img {
    filter: brightness(5);
    -webkit-filter: brightness(5);
}

.page-title h4 {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    color: #174B7C;
    margin: 0;
    column-gap: 14px;
}

.logout-btn {
    color: #174B7C !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    font-weight: 500;
    column-gap: 10px;
    margin-bottom: 50%;
    position: absolute;
}

.sidebar-bottom {
    /* background-image: url(../img/sidebar-bottom-bg.png); */
    position: relative;
    /* top: calc(100vh - 470px); */
    left: 0;
    /* min-height: 470px; */
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-top: auto;
}

.count-text {
    color: #174B7C;
    font-weight: 600;
    margin-top: 12px;
    opacity: 0.7;
    column-gap: 15px;
    display: flex;
    align-items: center;
}

.card-custom_box {
    border-radius: 20px;
    background: #F9F9FA;
}

.card-custom_box .h5 {
    color: #174B7C;
}

.sidebar {
    padding-bottom: 0 !important;
}

.sidebar:before {
    background: #fff;
    content: '';
    top: 100%;
    position: absolute;
    left: 0;
    width: 100%;
    height: 5000%;
}

.add-new-btn {
    background: #68CBE5;
}



.custom-table .dataTables_length,
.custom-table .dataTables_filter {
    margin: 16px 0;
}

.custom-table .dataTables_length label,
.custom-table .dataTables_filter label,
.dataTables_info {
    font-weight: 600 !important;
    color: #363954;
    font-size: 14px;
}

.custom-table .dataTables_length label select {
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 50px;
    box-shadow: 0px 0px 2px 0px #00000040;
    padding-left: 15px;
    border-color: #eee;
}

/* .custom-table .card {
    background: #F9F9FA;
    border-radius: 20px;
} */

.custom-table .dataTables_filter label input {
    margin-left: 15px !important;
    border-radius: 50px;
    box-shadow: 0px 0px 2px 0px #00000040;
    padding-left: 15px;
    border-color: #eee;
}

.custom-table th {
    color: #363954;
}

.custom-table th,
.custom-table td {
    font-size: 14px;
    font-weight: 400;
}

.custom-table td {
    color: #4A4A4A;
}


.dataTables_paginate li a {
    font-size: 14px;
    font-weight: 600;
    color: #68CBE5;
}

.custom-table .paginate_button.active a {
    background: #174B7C !important;
}

.custom-table .dataTables_info,
.custom-table .dataTables_paginate {
    margin: 15px 0 !important;
}

.custom-table .page-item:first-child .page-link {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.custom-table .page-item:last-child .page-link {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}


.action-btns {
    display: flex;
    column-gap: 10px;
}

.action-btns li {
    display: inline-block;
}

.action-btns li a,
.kiosk-btn {
    min-width: 30px;
    min-height: 30px;
    display: inline-block;
    line-height: 28px;
    text-align: center;
    border-radius: 50px;
    color: #fff !important;
}

.add-new-restaurant-box .nav-tabs li a {
    border: 1px solid #68CBE5;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 600;
    color: #174B7C;
    background: transparent;
    border-radius: 50px;
    padding: 6px 23px;
    outline: none !important;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #fff;
}

.add-new-restaurant-box .nav-tabs li a.active,
.add-new-restaurant-box .nav-tabs li a:hover {
    background: #68CBE5;
    color: #174B7C;
    border-color: transparent;
}


.custom_form .form-control,
.custom_form .css-13cymwt-control,
.custom_form .css-t3ipsp-control {
    border: 1px solid rgba(64, 75, 218, 0.3);
    border-radius: 24px;
    min-height: 52px;
    font-weight: 500;
    text-indent: 12px;
    box-shadow: none !important;
}

.custom_form .form-group {
    margin-bottom: 24px;
}

.custom_form .form-group label {
    font-weight: 500;
}

.custom_form .form-group .form-check-label {
    font-size: 14px;
    color: #8f8f8f;
    font-family: 'Poppins', sans-serif;
}

.custom_form .form-group .form-check {
    margin-top: 10px;
}

.custom_form .form-group .form-control::placeholder {
    color: #999ba8;
}




.bottom-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dark-btn {
    background: #F27A21;
    box-shadow: 0px 4px 25px rgba(64, 75, 218, 0.35);
    border-radius: 24px;
    border: 1px solid #F27A21 !important;
    color: #fff;
    font-weight: 600;
    padding: 17px 10%;
    outline: none !important;
    /* min-width: 250px; */
    text-transform: uppercase;
}

.border-btn {
    background: #fff;
    border-radius: 24px;
    border: 1px solid #174B7C !important;
    color: #174B7C;
    font-weight: 600;
    padding: 17px 10%;
    outline: none !important;
    /* min-width: 250px; */
    text-transform: uppercase;
}

.filter_btns {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.filter_btns>* {
    height: 28px;
    display: grid;
    place-items: center;
    padding: 0 10px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 4px;
    color: #615E5C;
    border: 1px solid #D7D8E0 !important;
    background: #F9F9FA;
    outline: none;
}


.add-plus-btn>input[type='file'] {
    display: none;
}

.add-edit-list {
    display: flex;
}

.add-plus-btn h6 {
    color: #596169;
    font-weight: 500;
    margin-top: 5px;
}

.add-plus-btn h6 span {
    display: block;
    font-weight: 400;
    margin-top: 5px;
}

.img-profile {
    mix-blend-mode: multiply;
}

.modal .close {
    opacity: 1 !important;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
}

.modal_details h3 {
    color: #4A4A4A;
    font-weight: 700;
    margin-bottom: 30px;
}

.modal_details p {
    font-size: 17px;
    font-weight: 400;
    color: #4A4A4A;
    justify-content: space-between;
    margin: 15px 0;
    display: flex;
}

.modal_details th,
.modal_details td {
    color: #4A4A4A;
}

.modal_details td {
    font-weight: 500;
}


.modal_details td small {
    display: block;
}

.order-user p {
    display: block;
    overflow-wrap: break-word;
}

.order-user {
    padding: 70px 0;
    border-bottom: 2px solid #eee;
}

#wrapper {
    align-items: flex-start;
    overflow: hidden;
}

body {
    background-color: #E6ECEF;
}


@media screen and (max-width: 1440px) {

    table.dataTable td,
    table.dataTable th {
        white-space: nowrap;
    }
}

@media screen and (max-width: 1240px) {
    .add_category select {
        font-size: 11px;
    }

    .choose_customize {
        padding: 12px 11px;
        font-size: 10px;
    }

    .add_category input {
        padding: 10px 8px;
        font-size: 10px;
    }
}

@media screen and (max-width: 991px) {
    .sidebar {
        padding: 10px;
    }

    .border-btn,
    .dark-btn {
        padding: 13px;
        min-width: 150px;
    }

    .sidebar-bottom {
        width: calc(100% + 22px);
        margin-left: -11px;
    }
}

@media screen and (max-width: 767px) {

    .custom_form .form-control {
        min-height: 40px;
        font-size: 14px;
    }

    .bottom-btns {
        flex-direction: column;
    }

    .sidebar-bottom {
        /* min-height: 170px;
        top: calc(100vh - 170px); */
        width: calc(100% + 10px);
        margin-left: -5px;
    }

    .sidebar .logout-btn {
        padding: 9px 5px;
        font-size: 12px;
    }

    .sidebar .logout-btn img {
        display: none;
    }

    .sidebar .sidebar-brand {
        padding: 0.5rem 0rem;
    }

    .sidebar-brand-logo img {
        max-width: 100%;
    }

    .sidebar-dark .nav-item .nav-link {
        padding: 8px 5px;
        margin-bottom: 10px;
    }

    .sidebar .nav-item .nav-link img {
        margin-right: 0;
    }

    .sidebar.toggled {
        padding: 0;
    }

    .page-title h4 {
        font-size: 16px;
    }

    .page-title h4 img {
        display: none;
    }

    .sidebar .nav-item .nav-link .img-profile,
    .topbar .nav-item .nav-link .img-profile {
        max-width: 65px;
    }

    .login-inner .h4.text-gray-900 {
        font-size: 18px;
    }

    .login-inner {
        padding: 40px 20px !important;
    }

    .login-wrapper {
        padding: 0 0 20px;
    }

    .filter_btns {
        flex-direction: column;
        align-items: normal;
        row-gap: 10px;
    }

    .sidebar {
        padding: 5px;
    }

    .sidebar .nav-item .nav-link span {
        overflow-wrap: break-word;
        line-height: 1.2;
        font-size: 10px;
        font-weight: normal;
    }

    .card-custom_box_row {
        flex-direction: column;
        text-align: center;
    }

    .add-new-restaurant-box .nav-tabs li {
        width: 100%;
        text-align: center;
    }
}


.form-group.product .form-check {
    border: 1px solid rgba(64, 75, 218, 0.3);
    border-radius: 24px;
    min-height: 52px;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 175px;
    padding: 10px 25px;
    justify-content: space-between;
    margin-top: 0;
    /* justify-content: space-between; */
    align-items: center;
    align-content: center;
    margin-right: 8px;
    margin-bottom: 7px;
}



.form-group.product {
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 0px;
    flex-flow: wrap;

}

.form-group.product input[type=checkbox],
.form-group.product input[type=radio] {

    accent-color: #68CBE5;
}


.custom_form .form-group.product .form-check-input {
    position: unset;
    margin-top: unset;
    margin-left: unset;

}

.form-group.product .bottom-btns .border-btn {
    width: 100%;
    max-width: 215px;
    padding: 24px, 0px, 24px, 0px;
}


button.view_btn {
    background: #174B7C;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    padding: 4px 6px;
    border-radius: 3px;
}




.profile_img_container img {
    width: 100%;
    max-width: 130px;
    background: #E6ECEF;
    padding: 15px 20px;
    border-radius: 13px;
    height: 130px;
    object-fit: contain;
}


/* .profile_img_container {
    position: relative;
} */

button.upload_image_btn {
    background: #5FC4BE;
    color: #ffff;
    border-radius: 5px;
    border: none;
    padding: 8px 20px;
    width: 100%;
    max-width: 130px;
}

.btn_img {
    position: relative;
}

.btn_img input {
    position: absolute;
    left: 12%;
    top: 50%;
    width: 130px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    opacity: 0;
}


.page-item.active .page-link {

    background-color: #174B7C;
    border-color: #174B7C;
}



.sidebar_div .ps-submenu-content.ps-open.css-18unl23 {
    background: transparent;
    margin-top: 20px;
}

.sidebar_div .ps-submenu-content.ps-open.css-18unl23 li.ps-menuitem-root.css-1tqrhto {
    margin-bottom: 2px;
}


.page-link {
    color: #68CBE5;
}


.text-danger,
.text-success {
    cursor: pointer;
}

.card-custom_box {
    cursor: pointer;
}

.add-new-restaurant-box select {
    border: 1px solid #404BDA4D;
    padding: 10px 20px;
    border-radius: 24px;
    outline: none !important;
    background-color: #FFFFFF !important;
    appearance: none;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAGCAYAAAAVMmT4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACJSURBVHgBhYrLDcIwEER3bHOnBBoAhQ44UkMIUkIHlJAO6ICVUNxOLGiAEkIB2cU+WAIUlHeazwNFTsfDVQkr+gNIn2IXZ5eK2LGFuD7G5YQ7iBkb5m5AXuqyLOBM/2uqkS2zDymbPLL3QVWaLzH2LCbs5xnuj1Bs1i8AeyVq+dZdaI66qnZT+xsUwi5IBDXelwAAAABJRU5ErkJggg==) no-repeat right center;
    background-position: 95%;
    background-size: 12px;
    padding-right: 20px;
    color: rgb(141, 141, 141);
    font-family: 800;
}


select.country_code_select_list {
    width: 100%;
    max-width: 122px;
    margin-right: 5px;
}


.allcountcode {
    display: flex;
    align-items: center;
    justify-content: space-between;
}