.menu-items-list{
    border: 1px solid #D7D8E0;
    border-radius: 10px;
}
.add_customization_btn{
   border: none;
   padding: 5px 18px;
   border-radius: 5px;
   color: white;
   background-color: #174B7C;

}

@media screen and (min-width:768px) and (max-width:1200px) {
.bundleSearch{
    position: relative;
    top: 20px;
    left: -80px !important;

}    
}