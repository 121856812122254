.sidebar_div aside.ps-sidebar-root.css-1gnzbyf {
    /* padding: 30px; */
    height: 100%;
    min-height: 100vh;
    border-color: transparent;
    border-right: none;
}


.sidebar-brand-logo img {
    max-width: 230px;
}

.sidebar_div nav.ps-menu-root.css-vj11vy {
    padding: 30px;
    padding-top: 0;
}

.sidebar-brand {
    color: #fff;
    /* padding: 30px; */
    text-align: center;
}

.sidebar-brand-logo {
    padding: 1.5rem 1rem;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.user-name {
    text-align: center;
    color: #fff;
    font-weight: 500;
    margin-bottom: 40px;
}

.user-name p {
    color: #fff;
    border-bottom: 1px solid #f9f9fa61;
    margin-bottom: 0;
    padding-bottom: 20px
}




.sidebar_div span.ps-menu-label.css-12w9als {
    font-size: 16px;
    display: inline;
    text-transform: capitalize;
    color: #93ACC3;
    /* padding: 8px 16px; */
    border-radius: 16px;
}


.sidebar_div span.ps-menu-icon.css-wx7wi4 path {
    fill: #93ACC3;
}


.sidebar_div span.css-honxw6,
.sidebar_div .css-jn69v9 {
    color: #93ACC3;
    width: 10px;
    height: 10px;
    margin-right: 2px;
}


.sidebar_div .css-16jesut>.ps-menu-button {
    padding-left: 0;
    padding-right: 0;
    height: 20px;
    /* margin-bottom: 45px; */
}

.sidebar_div ul li {
    margin-bottom: 45px;
}

.sidebar_div .css-1t8x7v1>.ps-menu-button {
    padding-left: 0;
    padding-right: 0;
    padding-top: 23px;

}

.sidebar_div .css-16jesut>.ps-menu-button:hover,
.sidebar_div .css-1t8x7v1>.ps-menu-button:hover {
    background-color: transparent;
}



.sidebar_div .ps-menu-button:hover>span.ps-menu-icon.css-wx7wi4 path,
.sidebar_div .ps-menu-button:hover>span.ps-menu-label.css-12w9als {
    fill: #fff;
    color: #fff;
}

.sidebar_div .svg-inline--fa {
    height: 1.4em;
}

.sidebar_div .ps-menu-button:hover span.css-jn69v9 {
    color: #fff !important;
}


.sidebar-bottom {
    /* background-image: url(../img/sidebar-bottom-bg.png); */
    position: relative;
    /* top: calc(100vh - 470px); */
    left: 0;
    /* min-height: 470px; */
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-top: auto;
}



.logout-btn {
    color: #174B7C !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    font-weight: 500;
    column-gap: 10px;
    margin-bottom: 50%;
    position: absolute;
    cursor: pointer;
}

.sidebar_div a.active .ps-menu-button span.ps-menu-icon.css-wx7wi4 path,
.sidebar_div a.active .ps-menu-button span.ps-menu-label.css-12w9als {

    fill: #fff;
    color: #fff;

}

.Mobile_menu {
    padding: 1rem 1px;
    margin: 0px 0;
    display: flex;
    align-items: center;
}

/* 
.menu_scolling {
    height: 100%;
    max-height: (100px, 400px);
    max-height: calc(100vh - 254px);
    overflow-y: scroll;
    overflow-x: hidden;
}





.menu_scolling::-webkit-scrollbar-track {
    
    border-radius: 5px;
    background-color: #D9D9D9;
}

.menu_scolling::-webkit-scrollbar {
    width: 5px;
    background-color: #D9D9D9;
}

.menu_scolling::-webkit-scrollbar-thumb {
    border-radius: 5px;
   
    background-color: #363954;
} */



/* @media screen and (max-width: 991px) {

    .sidebar_div.open .ps-menu-label,
    .sidebar_div.open a.logout-btn span,
    .sidebar_div.open .user-name {
        display: none !important;
    }

    .sidebar_div.open .sidebar-brand-logo img {
        max-width: 51px;
    }




    .sidebar_div.open .sidebar-bottom {
        width: 61px;
   
        margin-left: 0px;
        position: absolute;
        bottom: 0;
    }


    .sidebar_div.open nav.ps-menu-root.css-vj11vy {
        padding: 6px;
        padding-top: 0;
    }


    .sidebar_div.open .menu_scolling {
        height: 100%;
        max-height: calc(100vh - 191px);
        overflow-y: hidden;
        overflow-x: hidden;
    }


    .sidebar_div.open .sidebar-brand-logo {
        padding: 0.5rem 0rem;
        margin: 5px 0;
    }


    .sidebar_div.open .css-wx7wi4 {
        margin-right: 5px;
        width: 27px;
        min-width: 23px;
        height: 35px;
    }



    .sidebar_div.open button.toggle-button {
        text-align: center;
        width: 100%;
        position: absolute;
        bottom: 0;
        border: none;
        padding: 10px;
    }


    .sidebar_div.open ul li {
        margin-bottom: 30px;
    }


    .sidebar_div.open aside.ps-sidebar-root.css-1gnzbyf {
        width: 61px;
        min-width: 61px;
    }
} */


.newadd a.ps-menu-button .ps-menu-label,
.newadd a.ps-menu-button .css-jn69v9 {
    color: #fff;
}

.newadd a.ps-menu-button span.ps-menu-icon.css-wx7wi4 path {
    fill: #fff;
}


.icon_div {
    margin-left: 4px;
    cursor: pointer;
    color: #ffff;
}


.sidebar_div .ps-collapsed.app nav.ps-menu-root.css-vj11vy {
    padding: 20px;
}

.sidebar_div .ps-collapsed.app nav.ps-menu-root.css-vj11vy .ps-submenu-expand-icon {
    display: none;
}

body .css-nx2aea {
    background-color: transparent;
    margin-top: 20px;
}


.sidebar_div ul.css-ewdv3l li.css-1tqrhto {
    margin-bottom: 0px;
}

.Mobile_menu a.sidebar-brand img {
    min-width: 40px;
    max-width: 40px;
}



.ps-collapsed li.ps-menuitem-root.ps-submenu-root.sub_item.css-16jesut span.ps-menu-label.css-12w9als {
    margin-left: -30px !important;
    opacity: 0;
}



.ps-collapsed li.ps-menuitem-root.ps-submenu-root.sub_item.css-16jesut .ps-submenu-content.css-wlra1l span.ps-menu-label.css-12w9als {
    margin-left: 12px !important;
    opacity: 1;
}


.ps-collapsed li.ps-menuitem-root.ps-submenu-root.sub_item.css-16jesut .css-wlra1l {
    background-color: #004c7a;

}


.ps-collapsed li.ps-menuitem-root.ps-submenu-root.sub_item.css-16jesut li {
    margin-bottom: 10px;
}