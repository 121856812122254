body .orderplace {
    background-image: url("../../../../../img//bggkiosksystem.png");
    background-repeat: no-repeat;
    /* background-position: center; */
    background-size: 100%;
}


.bg_log img.branchlog {
    width: 336px;
    object-fit: contain;
    height: 138px;
    margin: 0 auto;
}

body .bg_log h1 {
    color: #174b7c;
    font-weight: 700 !important;
    font-size: 60px;
    line-height: 84px !important;
}


.Input_box img {
    width: 97px;
    height: 97px;
    margin: 0 auto;
}




.Input_box label {
    color: #1B4B7C;
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 38px;
}

.Input_box {
    display: flex;
    flex-flow: column;
    justify-content: center;
    gap: 20px;
    background: #FFFFFF;
    border-radius: 28px;
    width: 100%;
    max-width: 270px;
    padding: 54px;
    margin: 0 20px;
    position: relative;
}

.bg_log {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 64px;
}


.menudata {
    display: flex;
    /* width: 100%; */
    justify-content: center;
}

.Input_box.selected {
    background-color: #F27A21;
}

.Input_box.selected label {
    color: #fff;
}


.Input_box input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    opacity: 0;
    cursor: pointer;
}




.odersection h5 {
    font-size: 32px;
    font-weight: 600;
    align-items: center;
    color: #174B7C;
}



.img_group {
    display: flex;
    flex-flow: wrap;
    margin-top: 21px;
}

.box_img .big_img {
    width: 100%;
    max-width: 350px;
    height: 220px;
    object-fit: inherit;
    margin: 5px 5px;
    cursor: pointer;
    min-width: 350px;
    border-radius: 8px;
}

.box_img .smail_img {
    width: 206px;
    height: 220px;
    object-fit: inherit;
    margin: 5px 5px;
    cursor: pointer;
    border-radius: 8px;
}


.box_img .smail_img {
    width: 206px;
    height: 220px;
    object-fit: inherit;
    margin: 5px 5px;
    cursor: pointer;
    border-radius: 8px;
}


.box_img {
    position: relative;
}


.box_img p {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10px, 10px);
    background: #ff7434;
    color: #fff;
    padding: 8px 10px;
    border-radius: 4px;
    font-weight: 700;
}

.Cardplus {
    display: flex;
    align-items: normal;
    justify-content: center;
    margin-top: 7px;
}

@media screen and (max-width: 1550px) {
    body .box_img .big_img {
        max-width: 330px;
        min-width: 330px;

    }

    body .img_group {
        justify-content: space-between;
    }
}

@media screen and (max-width: 1440px) {
    body .orderplace {
        background-size: 100% 100vh;
    }



    body .box_img .big_img {
        max-width: 290px;
        min-width: 290px;

    }

    body .img_group {
        justify-content: space-between;
    }

    body .Tab_menu ul#myTab .nav-link {
        font-size: 14px;
    }

    ul#mybundel li {
        width: 100%;
    }

    body .item_add_left_side_cont {
        padding: 22px 16px;
    }


}


@media screen and (max-width: 1366px) {
    body .box_img .big_img {
        max-width: 275px;
        min-width: 275px;
        height: 185px;
    }

    body .box_img .smail_img {
        width: 180px;
        height: 185px;
    }

    body .box_img p {
        font-size: 11px;
    }

    body .Tab_menu ul#myTab .nav-link {
        font-size: 16px;
        padding-top: 20px;
        padding-bottom: 12px;

    }

    body .Tab_menu div#myTabContent {
        background: #fff;
        padding: 30px 11px;
    }

    body div#accordion .card-header {
        padding-top: 30px;
        padding-left: 18px;
        padding-right: 18px;
    }

    body .cart_text h5 {
        font-size: 26px;
    }


    body .cart_text {
        max-width: 380px;
    }

    body .myrediv h5.hef {
        font-size: 24px;
    }

    body .usernameres p {
        font-size: 14.623px;
    }

    body .usernameres {
        margin-bottom: 22px;
        /* max-width: 183px; */
    }

    body .usernameres h5 {
        font-size: 14.623px;
    }

}


@media screen and (max-width: 1240px) {
    body .box_img .big_img {
        max-width: 240px;
        min-width: 240px;
        height: 150px;
    }


    body .box_img .smail_img {
        width: 155px;
        height: 150px;
    }

    body .odersection h5 {
        font-size: 24px;
    }


    body .img_group {
        margin-top: 10px;
    }

    body .order_category_cont {
        padding: 6px 0px;
        gap: 5px;
    }

    body section.Restaurants.Dashbored.allsection.menuorder {
        padding: 0;
    }

    body body .category_image_1 {
        max-width: 96px;
        min-width: 91px;
        height: 100%;
        max-height: 90px;
        min-height: 88px;

    }

    body .order_category_cont p {
        font-size: 12px;
    }

    body .item_Container {
        max-height: 304px;

    }

    body .item_Container .tag_1 {
        font-size: 12px;
    }

    body .category_item_container {
        margin-top: 7px;
    }

    body .Tab_menu ul#myTab .nav-link {
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    body .Tab_menu div#myTabContent {
        background: #fff;
        padding: 15px 11px;
    }

    body ul.indredent_item li {
        color: #454545;
        font-size: 14px;
    }

    body .customizationrediobtn label.form-check-label {
        font-size: 16px;
    }

    body .customizationrediobtn .form-check {
        padding: 15px 25px;
        height: 100px;
        width: 245px;
    }

    body .customizationrediobtn .form-check.Cardsection {
        width: 170px;

    }

    body .bundleCategories .customizationbundle .bundle_card {
        margin-right: 5px;
    }

    body .bundleCategories .customizationbundle .bundle_name {
        margin-bottom: 20px;
    }

    div#accordion .card-header {
        border-bottom: 0;
    }

    .new_errror {
        font-size: 11px;
    }

    body .cart_text h5 {
        font-size: 20px;
    }

    body .cart_text {
        max-width: 300px;
    }

    body .all_cart_btn button {
        font-size: 15px;
        padding: 6px 24px;
        margin-right: 9px;
        margin-top: 13px;
    }



}



@media screen and (max-width: 1140px) {

    body .box_img .big_img {
        max-width: 215px;
        min-width: 215px;
        height: 120px;
    }


    .box_img .smail_img {
        width: 128px;
        height: 123px;
    }

    body .category_image_1 {
        max-width: 80px;
        min-width: 80px;
        max-height: 66px;
        min-height: 66px;
    }

    body .order_category_cont {
        padding: 12px 6px;
    }

    body .category_page_subheading2 {
        font-size: 26px;
    }


    body .item_add_left_side_cont .image_cont h5 {
        font-size: 20px;
        line-height: 22px !important;
        margin-bottom: 9px !important;
        margin-top: 5px !important;
    }

    body .Tab_menu ul#myTab .nav-link {
        font-size: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    body .tab-pane.fade.show {
        position: unset !important;
    }

    body .item_add_left_side_cont {
        max-height: 360px;
        min-height: 360px
    }

    body .customizationrediobtn .form-check {
        padding: 10px 16px;
        width: 216px;
        height: 81px
    }

    body div#accordion .card-header {
        padding-bottom: 0;
    }

    body .all_cart_btn button {
        font-size: 12px;
        padding: 4px 15px;
        margin-right: 9px;
        margin-top: 13px;
    }


    body .mycart .Card_img .imgcart img {
        max-width: 150px;
        max-height: 150px;
    }

    body .ContinueOrder .btn {
        font-size: 16px;
    }

    body .Myorder .Yourdetails {
        font-size: 14px;
    }

    body .Myorder .Detailsuser label {
        font-size: 14.565px;
    }

    body .Myorder .Detailsuser input {
        font-size: 14.636px;
    }

    body button.Apply {
        font-size: 14px;
    }

    body .Myorder .Card_list button.btn.btncard {
        font-size: 14.707px;
    }

    body .Myorder .Card_list .card_img {
        font-size: 16.707px;
    }

    body ul.Discoun_p h5 {
        font-size: 18px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    body .bundleCategories .customizationbundle h5 {
        font-size: 20px;
    }
}



@media screen and (max-width: 1024px) {
    body .box_img .big_img {
        max-width: 355px;
        min-width: 355px;
        height: 220px;
    }


    .box_img .smail_img {
        width: 240px;
        height: 220px;
    }

    body .item_add_left_side_cont {
        max-height: 360px;
        min-height: 360px
    }


    body .cart_text {
        max-width: 224px;
    }
}