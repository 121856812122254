.add_category{
    border-radius: 20px;
    padding: 20px;
    border: 1px solid #D7D8E0
}
 .add_category .heading_name{
    color: #174B7C;

}

.add_category input{
    border: 1px solid #404BDA4D;
    padding: 10px 20px;
    border-radius: 24px;
    outline: none !important;
    background-color: #FFFFFF !important;
    
}

.add_category textarea{
    border: 1px solid #404BDA4D;
    padding: 10px 20px;
    border-radius: 24px;
    outline: none !important;
    background-color: #FFFFFF !important;
    
}
.add_category select {
    border: 1px solid #404BDA4D;
    padding: 10px 20px;
    border-radius: 24px;
    outline: none !important;
    background-color: #FFFFFF !important;
    -webkit-appearance: none;  /* Safari and Chrome */
    -moz-appearance: none;     /* Firefox */
    appearance: none;

    background: url('../../../../../img/AddModal/Polygon\ 1.png') no-repeat right center;
    background-position: 95%;
    background-size: 12px;
    padding-right: 20px; /* Adjust this value based on your custom arrow size */

}




    .add_category .rmsc .dropdown-container {
       
        background-color: #FFFFFF !important;
        border: 1px solid #404BDA4D;
        border-radius: 24px;
    }
    .big_image {
        position: relative;
    }
    .small_image {
        position: relative;
    }
    .category_img_browse_container button {
        border: none;
        color: white;
        background-color: #5FC4BE;
        width: 100%;
        padding: 10px;
        border-radius: 5px;
    }

    .category_img_browse_container input[type='file']:nth-last-child(1){
        position: absolute;
        top: 0px;
        left: 0px;
        border-radius: 0px !important;
        width: 100%;
        padding: 6px 0px;
        opacity: 0;
    }
    .category_cancel{
        border: 1px solid #174B7C;
        color: #174B7C;
        outline: none !important;
        border-radius: 24px;
        padding: 14px 50px;
        text-align: center;
        cursor: pointer;
        transition: 0.3s ease-in-out;
    }
    .category_cancel:hover{
       background-color: #F27A21;
       color: white;

    }
    .category_save{
        box-shadow: 0px 4px 25px 0px #F27A2133;
        background: #F27A21;
        border: none !important;
        outline: none !important;
        border-radius: 24px;
        padding: 18px 50px;
        color: #F9F9FA;
        font-weight: 600;
    }

   .category_images{
    width: 100%;
    max-width: 80px;
    height: 100%;
    min-height: 75px;
   }