.topbar {
    height: 4.375rem;
    box-shadow: 0px 2px 2px 0px #272A4517;
}


.Dashbored .topbar .page-title h4 {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    color: #174B7C;
    margin: 0;
    column-gap: 14px;
}


.navbar {
    padding: .5rem 1rem;
    flex-flow: row nowrap;
    justify-content: space-between;
}


img.img-profile.imgpro {
    background: #004c7a;
    padding: 7px 9px;
    border-radius: 11px;
}


img.img-profile {

    border-radius: 11px;
    width: 123px;
    height: 64px;
    object-fit: contain;
}


button.cart_view {
    padding: 0;
    border: none;
    background: transparent;
    position: relative;
}


button.cart_view img {
    width: 53px;
}


button.cart_view span {
    position: absolute;
    color: #ffff;
    font-weight: 700;
    font-size: 14px;
    left: 46px;
    top: 9px;
    transform: translate(-50%, -50%);
    background-color: #ff7434;
    padding: 4px 5px;
    border-radius: 27px;
    width: 29px;
}