.add_category input.input_check_box {
    width: 18px;
    height: 18px;
}

.choose_customize {
    border: 1px solid #174B7C;
    padding: 12px 20px;
    white-space: pre;
    margin: 0px;
    border-radius: 27px;
    color: #174B7C;
    font-weight: 500;
    margin-top: 30px;
    cursor: pointer;


}

.add_more_field_btn {
    text-align: center;
}

.meal_item_price_inputs {
    width: 100%;
    max-width: 80px;
    border-radius: 10px !important;
}

input.meal_customization_field {
    width: 100%;
}

.mealCustomization_section {
    overflow-y: auto;
    height: 100%;
    max-height: 1090px;
}



button.Remove_btn {
    border: none;
    background: #5FC4BE;
    color: white;
    padding: 15px 25px;
    border-radius: 30px;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    /* float: right; */
}


.item-section {
    margin-bottom: 20px;
}
.category_cancel:hover{
    box-shadow: none;
    background-color: #F27A21;
    color: white;
    transition: 0.3s ease-in-out;
}
