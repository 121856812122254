.view-order {
    background: #1B4B7C;
    padding: 17px 16px;
    text-align: center;
}



.view-order button {
    border-radius: 10.353px;
    background: #EEF7FF;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    border: none;
    padding: 10px 16px;
    margin: 0 4px;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.view-order button:hover,
.removeoder .close_btn button:hover {
    background-color: #F27A21;
    color: #fff;
}



.bg_img img {
    width: 96px;
    position: absolute;
    top: 97%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.bg_img {
    text-align: center;
    /* background-image: url("../../../../../../img/big.svg"); */
    background: #1B4B7C;
    padding: 65px 0;
    position: relative;
}

.removeoder .modal_heading h5 {
    color: #1B4B7C;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.removeoder .close_btn button {
    border-radius: 10.353px;
    background: #1B4B7C;
    box-shadow: 0px 0px 5.177px 0px rgba(0, 0, 0, 0.25);
    outline: none;
    border: none;
    padding: 10px 16px;
    margin: 0 4px;
    color: #ffff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    max-width: 154px;
}

.removeoder .close_btn {
    display: flex;
    justify-content: center;
}



.removeoder .modal_heading {
    padding: 50px 0;
}

.Myorder .GiftCard .AddTip ul li span:nth-child(2) {
    font-size: 14px;
}