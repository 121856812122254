.Tippingmenu .tip_Text h5 {
    color: #F27A21;
    /* font-family: Inter; */
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
}

.Tippingmenu .tip_Text p {
    color: #000;
    /* font-family: Quicksand; */
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0;
}

.Tippingmenu {
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: #FFF;
    padding: 33px 36px;
    height: 100%;
}

.Tippingmenu .tip_img {
    margin-right: 20px;
}

button.Details_btn {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 4px;
    background: #68CBE5;
    outline: none;
    border: none;
    padding: 10px 12px;
    border-radius: 8px;
}


.Date_section .date-picker-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.Date_section .calendar-icon {
    position: absolute;
    right: 10px;
    pointer-events: none;
    color: #555;
    z-index: 9;
}

.Date_section .date-picker-wrapper .form-control {
    /* padding-left: 0px !important; */
    margin-left: 0 !important;
    border-radius: 50px;
    box-shadow: 0px 0px 2px 0px #00000040;
    padding: 1.25rem 0.5rem;
    /* Adjust this value as needed */
}

.Date_section label {
    font-weight: 600 !important;
    color: #363954;
    font-size: 14px;
    display: flex;
    align-items: baseline;
}

.Date_section {
    float: inline-end;
}


.MonthlyTip .modal_heading p {
    color: #363954;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.Date_pic .date-picker-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    max-width: 269px;
}

.Date_pic .calendar-icon {
    position: absolute;
    right: 10px;
    pointer-events: none;
    color: #555;
    z-index: 9;
}

.Date_pic .date-picker-wrapper .form-control {
    margin-left: 0 !important;
    border-radius: 50px;
    box-shadow: 0px 0px 2px 0px #00000040;
    padding: 1.25rem 0.5rem;
    /* Adjust this value as needed */
}

.Table_data {
    margin-top: 56px;
}




.Table_data {
    height: 100%;
    max-height: (100px, 400px);
    max-height: calc(100vh - 254px);
    overflow-y: scroll;
    overflow-x: hidden;
}





.Table_data::-webkit-scrollbar-track {

    border-radius: 5px;
    background-color: #D9D9D9;
}

.Table_data::-webkit-scrollbar {
    width: 5px;
    background-color: #D9D9D9;
}

.Table_data::-webkit-scrollbar-thumb {
    border-radius: 5px;

    background-color: #363954;
}

@media screen and (max-width: 1666px) {


    .Tippingmenu {
        padding: 30px 20px;
    }

    .Tippingmenu .tip_Text h5 {
        font-size: 20px;
    }

    .Tippingmenu .tip_Text p {
        font-size: 14px;
    }
}


@media screen and (max-width: 1440px) {
    .Tippingmenu .tip_Text h5 {
        font-size: 18px;
    }

    .Tippingmenu .tip_Text p {
        font-size: 12px;
    }

    .Tippingmenu {
        padding: 20px 15px;
    }
}